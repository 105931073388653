import React from 'react'
import { useLocation } from 'react-router-dom'
import useScript from '../../hooks/useScript'
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, onValue, get } from "firebase/database";
import { getFirestore, doc, updateDoc, setDoc } from "firebase/firestore";
import { getIceServers, defaultIceServers, defaultSocketURL, setDefaultVideoConnectionSettings } from '../../webrtc_configs';


const firebaseConfig = {
    // Your Firebase project configuration
    apiKey: "AIzaSyDpVFrQFD9bF9asUMfoqPTDbd33seUs4mM",
    authDomain: "elosports.firebaseapp.com",
    databaseURL: "https://elosports.firebaseio.com",
    projectId: "elosports",
    storageBucket: "elosports.appspot.com",
    messagingSenderId: "793217318287",
    appId: "1:793217318287:web:9c0f9c3da2f483e8789ffa"
};

const app = initializeApp(firebaseConfig);

function Player() {
  useScript('https://webrtc.evosports.live:9001/dist/RTCMultiConnection.min.js')
  useScript('https://webrtc.evosports.live:9001/socket.io/socket.io.js')

  const [effectState, setEffectState] = React.useState(false);
  const videoRef = React.useRef(null)
  const audioRef = React.useRef(null)
  const location = useLocation()
  const [audioSrcObject, setAudioSrcObject] = React.useState(null);
  const [sessionStarted, setSessionStarted] = React.useState(false);
  const [roster, setRoster] = React.useState({});

  React.useEffect(() => {
    if (audioRef.current) {
      audioRef.current.srcObject = audioSrcObject
    }
  }, [audioSrcObject, audioRef])

  React.useEffect(() => {
    async function start() {
      // detect the reload signal from realtime database
      const tableId = location.pathname.substring(1).replace('publish/', '');
      const userId = "EVOBOX"

      // firebase part
      const database = getDatabase(app);
      const restartRef = ref(database, `webrtc/${tableId}/restart`);

      // webrtc part
      var videoConnection = null;
      var audioConnection = null;

      // set restart flag to false
      set(ref(database, `webrtc/${tableId}/restart`), false);

      // waiting for the restart flag to be set to true
      onValue(restartRef, (snapshot) => {
        const restartFlag = snapshot.val();
        if (restartFlag === true) {
          // Reload the current page
          window.location.reload();
        }
      });

      const onRosterChange = (event) => {
        console.log("onrosterchange");

        // get all the keys which contains '_' in it from audioConnection.peers and convert it to array, and also remove '_' and following characters from the key
        const members = Object.keys(videoConnection.peers).filter(key => key.includes('_')).map(key => key.split('_')[0]);

        const db = getFirestore(app);
        // update only members key in rosters/tableId/members
        updateDoc(doc(db, `rosters`, `${tableId}`), { members: members });
      }

      const initRosterOnFirestore = () => {
        const db = getFirestore(app);
        // set rosters/tableId/members to empty array
        setDoc(doc(db, `rosters`, `${tableId}`), { members: [], membersData: [] });
      }

      initRosterOnFirestore();


      var getCameras = async function () {
        try {
          // Request permission and get the list of devices
          navigator.mediaDevices.getUserMedia({ audio: true, video: true });
          const devices = await navigator.mediaDevices.enumerateDevices();

          // Filter out the video input devices (cameras)
          const cameras = devices.filter(device => device.kind === 'videoinput' && device.label === "OBS Virtual Camera");
          console.log(cameras);

          return cameras;
        } catch (error) {
          console.error('Error getting cameras:', error);
          return [];
        }
      }
      var getObsVirtualCamera = async function () {
        const cameras = await getCameras();

        if (cameras.length > 0) {
          // Example: Select the first camera
          return cameras[0];
        } else {
          console.error('No OBS Virtual camera found');
          return null;
        }
      }
      var obsVirtualCamera = await getObsVirtualCamera();

      console.log("obsVirtualCamera: ");
      console.log(obsVirtualCamera);
      console.log("obsVirtualCamera.deviceId: ", obsVirtualCamera.deviceId);

      navigator.mediaDevices.getUserMedia({ audio: true, video: { deviceId: { exact: obsVirtualCamera.deviceId } } }).then(s => {
        const startVideoConnection = () => {
          console.log("START VIDEO CONNECTION")
          videoConnection = new RTCMultiConnection() // eslint-disable-line
          setDefaultVideoConnectionSettings(videoConnection, tableId, userId);

          videoConnection.mediaConstraints.video = {
            deviceId: obsVirtualCamera.deviceId
          };

          console.log(videoConnection.mediaConstraints);

          // broadcast video stream, so no receiving video stream
          videoConnection.sdpConstraints.mandatory = {
            OfferToReceiveAudio: false,
            OfferToReceiveVideo: false
          }
          videoConnection.autoCloseEntireSession = true;
          videoConnection.onUserStatusChanged = onRosterChange;
          videoConnection.openOrJoin(`${tableId}video`)

        }
        setTimeout(startVideoConnection, 500);


        const startAudioConnection = async () => {
          console.log("START AUDIO CONNECTION")
          // enable audio conference for evobox microphone
          audioConnection = new RTCMultiConnection(); // eslint-disable-line
          audioConnection.userid = "evobox_" + audioConnection.token();
          audioConnection.socketURL = defaultSocketURL;
          audioConnection.channel = audioConnection.sessionid = `${tableId}audio`;


          audioConnection.session = {
            audio: true,
            video: false,
            data: true
          };

          audioConnection.mediaConstraints = {
            audio: true,
            video: false
          }

          audioConnection.sdpConstraints.mandatory = {
            OfferToReceiveAudio: true,
            OfferToReceiveVideo: false
          }

          audioConnection.videosContainer = document.querySelector("#audio-container");
          audioConnection.iceServers = await getIceServers();
          audioConnection.openOrJoin(`${tableId}audio`);
          setTimeout(onRosterChange, 500);
        };
        setTimeout(startAudioConnection, 500);

        console.log("get user media")
      })

      return function cleanup() {
        const closeConnections = (connection) => {
          if (connection) {
            connection.getAllParticipants().forEach(function (pid) {
              connection.disconnectWith(pid);
            });
            connection.attachStreams.forEach(function (localStream) {
              localStream.stop();
            });
            connection.closeSocket(connection.sessionid);
          }
        }
        closeConnections(videoConnection);
        closeConnections(audioConnection);
      }
    }
    setTimeout(() => {
      start();
    }, 2000);
  }, [effectState, location.pathname])

  return (
    <div className='container mx-auto'>
      <div id="video-container"></div>
      <div id="audio-container"></div>
    </div>
  )
}



export default Player

import React, { useRef, useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, onValue } from "firebase/database";


const firebaseConfig = {
    // Your Firebase project configuration
    apiKey: "AIzaSyDpVFrQFD9bF9asUMfoqPTDbd33seUs4mM",
    authDomain: "elosports.firebaseapp.com",
    databaseURL: "https://elosports.firebaseio.com",
    projectId: "elosports",
    storageBucket: "elosports.appspot.com",
    messagingSenderId: "793217318287",
    appId: "1:793217318287:web:9c0f9c3da2f483e8789ffa"
};

const app = initializeApp(firebaseConfig);
const realtimedb = getDatabase(app);

const Canvas = ({ tableId = "colin_1", thisRef }) => {
    const canvasRef = useRef(null);
    let traces = [];
    let mouseMode = 0; // 0: draw, 1: straight line

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        thisRef.current.canvas = canvas;


        canvas.width = 1920;
        canvas.height = 1080;

        // Array to store the traces
        // Variable to store the current trace
        let currentTrace = null;
        let number = 0;
        let fpsSkipped = 3;
        const fadeoutVelocity = 0.0008;
        let isDesktop = true;
        let currentColor = 0;
        let colors = [
            "25, 144, 100",
            "5, 81, 174",
            "255, 2, 2",
            "255, 248, 250",
            "184, 56, 99",
            "66, 218, 141",
            "47, 60, 186",
            "125, 61, 166",
            "38, 165, 157",
            "219, 136, 44",
            "152, 55, 174",
            "209, 252, 227",
            "98, 236, 107",
            "156, 176, 241",
            "30, 140, 97",
            "202, 141, 16",
            "109, 162, 165",
            "165, 53, 211",
            "204, 88, 221",
            "255, 142, 64",
            "247, 157, 13",
            "178, 163, 228",
            "29, 29, 45",
            "198, 40, 37",
            "11, 218, 243",
            "249, 214, 122",
            "17, 111, 7",
            "14, 115, 78",
            "56, 49, 188",
            "85, 185, 198"
        ];


        let colorNum = colors.length;

        // Subscribe to database canvas update
        const dbRef = ref(realtimedb, `canvas/${tableId}/traces`);
        onValue(dbRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                traces = data; // This will throw an error since traces is a const array
                if (currentTrace) {
                    // find the trace in the traces array with has the same traceId
                    const trace = traces.find(trace => trace.traceId === currentTrace.traceId);
                    currentTrace = trace;
                }
            }
        });

        function getRandomGuid(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        }

        function getColor() {
            const randomIndex = Math.floor(Math.random() * colors.length);
            return colors[randomIndex];
        }
        
        function setColor(color) {
            colors = [color];
            colorNum = colors.length;
            currentColor = 0;
        }

        function convertHexToRGB(hex) {
            // get the RGB value
            const r = parseInt(hex.substring(0, 2), 16);
            const g = parseInt(hex.substring(2, 4), 16);
            const b = parseInt(hex.substring(4, 6), 16);
            return `${r}, ${g}, ${b}`;
        }

        function getColorInHexFormat(){
            // now the color is in "R, G, B" format, return a hex format one
            const color = colors[currentColor];
            const colorArr = color.split(",");
            let hexColor = "#";
            for (let i = 0; i < colorArr.length; i++) {
                let hex = Number(colorArr[i]).toString(16);
                if (hex.length < 2) {
                    hex = "0" + hex;
                }
                hexColor += hex;
            }
            return hexColor;
        }
        thisRef.current.setColor = setColor;
        thisRef.current.getColor = getColor;
        thisRef.current.getColorInHexFormat = getColorInHexFormat;
        // if url paramater has color=RRGGBB, then set the color
        const urlParams = new URLSearchParams(window.location.search);
        const colorParams = urlParams.get('color');
        if (colorParams) {
            setColor(convertHexToRGB(colorParams));
        } else {
            setColor(getColor())
        }

        function handleClear(event) {
            // remove all items in traces
            traces.splice(0, traces.length);

            // push the traces to facebase realtime database
            const db = getDatabase(app);
            set(ref(db, `canvas/${tableId}/traces`), [
                {
                    color: getColor(),
                    opacity: 0.001,
                    points: [{ x: 0, y: 0 }, { x: 0, y: 0 }],
                    traceId: getRandomGuid(8)
                }
            ]);
        }

        thisRef.current.handleClear = handleClear;

        // Function to handle touch or pointer down event
        function handleDown(event) {
            // fix bug that click and touch will double fire
            if (currentTrace) {
                return;
            }

            if (event.pointerType === "mouse") {
                isDesktop = true;
            } else if (event.pointerType === "touch") {
                isDesktop = false;
            }

            // Prevent scrolling on touch devices
            event.preventDefault();

            // Start a new trace
            currentTrace = {
                traceId: getRandomGuid(8),
                color: getColor(),
                points: [],
                opacity: 1
            };

            // prevent right click menu
            canvas.addEventListener('contextmenu', function (e) {
                e.preventDefault();
            });

            // if mouse key pressed is right click, set mosueMode to 1
            // if mouse key pressed is left click, set mouseMode to 0
            if (event.button === 2) {
                mouseMode = 1;
            } else {
                mouseMode = 0;
            }

            // Add the current position to the trace
            const { offsetX, offsetY } = event.touches ? event.touches[0] : event;
            currentTrace.points.push({ x: offsetX, y: offsetY });

            // Add the trace to the traces array
            traces.push(currentTrace);

            // Add event listeners for touch or pointer move and up events
            canvas.addEventListener('pointermove', handleMove);
            canvas.addEventListener('touchmove', handleMove, { passive: false });
            canvas.addEventListener('pointerup', handleUp);
            canvas.addEventListener('touchend', handleUp);

            // when mouse is moving outside the canvas, we need to trigger handleUp
            canvas.addEventListener('pointerleave', handleUp);
            canvas.addEventListener('touchcancel', handleUp);
        }

        // Function to handle touch or pointer move event
        function handleMove(event) {
            // Prevent scrolling on touch devices
            event.preventDefault();

            // Get the current position
            const offsetX = event.touches ? event.touches[0].clientX : event.offsetX;
            const offsetY = event.touches ? event.touches[0].clientY : event.offsetY;

            // Check if the current position is outside the canvas area
            if (offsetX < 0 || offsetX > canvas.width || offsetY < 0 || offsetY > canvas.height) {
                // Trigger handleUp function
                handleUp();
                return;
            }

            // mobile doesn't need the latency logic to strengthen the line
            if (!isDesktop) {
                // Add the current position to the current trace
                //it is touch and there's two finger, then we just draw the line from first finger to second finger
                if (event.touches && event.touches.length === 2) {
                    currentTrace.points = [
                        { x: event.touches[0].clientX, y: event.touches[0].clientY },
                        { x: event.touches[1].clientX, y: event.touches[1].clientY }
                    ];
                } else {
                    currentTrace.points.push({ x: offsetX, y: offsetY });
                }
            } else if (isDesktop && number % fpsSkipped === 0) {
                // put latency intentionally for desktop to straighten the trace
                // Add the current position to the current trace

                if (mouseMode === 0) {
                    // draw mode    
                    currentTrace.points.push({ x: offsetX, y: offsetY });
                } else if (mouseMode === 1) {
                    // always update the second point to the current position
                    currentTrace.points[1] = { x: offsetX, y: offsetY };
                    // remove all the remaining points
                    currentTrace.points.splice(2, currentTrace.points.length - 2);
                }

                // push the traces to facebase realtime database
                const db = getDatabase(app);
                set(ref(db, `canvas/${tableId}/traces`), traces);
            }

            number = number % fpsSkipped + 1;
        }

        // Function to handle touch or pointer up event
        function handleUp() {
            // Remove the event listeners for touch or pointer move and up events
            canvas.removeEventListener('pointermove', handleMove);
            canvas.removeEventListener('touchmove', handleMove);
            canvas.removeEventListener('pointerup', handleUp);
            canvas.removeEventListener('touchend', handleUp);
            canvas.removeEventListener('pointerleave', handleUp);
            canvas.removeEventListener('touchcancel', handleUp);

            // Reset the current trace
            currentTrace.opacity = 1;
            currentTrace = null;

            // push the traces to facebase realtime database
            set(ref(realtimedb, `canvas/${tableId}/traces`), traces);
        }

        // Add event listeners for touch or pointer down event
        canvas.addEventListener('pointerdown', handleDown);
        canvas.addEventListener('touchstart', handleDown);

        // Function to draw the traces on the canvas
        function drawTraces() {
            // Clear the canvas
            context.clearRect(0, 0, canvas.width, canvas.height);

            // Set the line join and line cap properties for smooth strokes
            context.lineJoin = "round";
            context.lineCap = "round";

            // Draw each trace on the canvas
            for (let i = 0; i < traces.length; i++) {
                const trace = traces[i];

                // Set the color and opacity for the trace
                context.strokeStyle = `rgba(${trace.color}, ${trace.opacity})`;
                context.lineWidth = 4;

                // Draw the trace
                context.beginPath();
                context.moveTo(trace.points[0].x, trace.points[0].y);

                for (let j = 1; j < trace.points.length; j++) {
                    context.lineTo(trace.points[j].x, trace.points[j].y);
                }

                context.stroke();
            }
        }

        // Function to fade out the traces
        function fadeOutTraces() {
            // Reduce the opacity of each trace
            for (let i = 0; i < traces.length; i++) {
                const trace = traces[i];
                trace.opacity -= fadeoutVelocity;

                // Remove the trace if the opacity is less than or equal to 0
                if (trace.opacity <= -1) {
                    traces.splice(i, 1);
                    i--;
                    // push the traces to facebase realtime database
                    set(ref(realtimedb, `canvas/${tableId}/traces`), traces);
                }
            }
        }

        // Function to animate the canvas
        function animateCanvas() {
            drawTraces();
            fadeOutTraces();
            requestAnimationFrame(animateCanvas);
        }

        animateCanvas()

    }, []);

    return <canvas className='streamsize canvas' ref={canvasRef} />;
};
export default Canvas;
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Canvas from './Canvas'
import useScript from '../../hooks/useScript'
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, update } from "firebase/database";
import { getFirestore, doc, setDoc, updateDoc, getDoc, getDocs, collection } from "firebase/firestore";
import { defaultIceServers, getIceServers, defaultSocketURL,  setDefaultVideoConnectionSettings } from '../../webrtc_configs'
import Cueball from './Cueball'

const firebaseConfig = {
    // Your Firebase project configuration
    apiKey: "AIzaSyDpVFrQFD9bF9asUMfoqPTDbd33seUs4mM",
    authDomain: "elosports.firebaseapp.com",
    databaseURL: "https://elosports.firebaseio.com",
    projectId: "elosports",
    storageBucket: "elosports.appspot.com",
    messagingSenderId: "793217318287",
    appId: "1:793217318287:web:9c0f9c3da2f483e8789ffa"
};

const app = initializeApp(firebaseConfig);

export const CinemaModeContext = React.createContext(null);

export function CinemaModeProvider({ children }) {
  const [cinemaMode, setCinemaMode] = useState(() => localStorage.getItem("cinema-mode") === "true");
  const state = useMemo(() => ({
    cinemaMode,
    setCinemaMode,
    toggleCinemaMode: () => setCinemaMode((prev) => !prev),
  }), [cinemaMode, setCinemaMode]);

  useEffect(() => localStorage.setItem("cinema-mode", cinemaMode), [cinemaMode]);
  return (
    <CinemaModeContext.Provider value={state}>
      {children}
    </CinemaModeContext.Provider>
  );
}

var audioConnection = null;
var videoConnection = null;
var video_joined = false;
var audio_joined = false;
var firstTouch = false;
var audioMode = true

function PlayerPage() {
  useScript('https://webrtc.evosports.live:9001/dist/RTCMultiConnection.min.js')
  useScript('https://webrtc.evosports.live:9001/socket.io/socket.io.js')
  useScript('https://cdn.webrtc-experiment.com/hark.js')
  const location = useLocation()
  const tableId = location.pathname.substring(1)
  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get('u');
  audioMode = urlParams.get('audio') ? false : true;
  const [muted, setMuted] = useState(false);
  const restartWebrtcPublish = () => {
    // push the traces to facebase realtime database
    const db = getDatabase(app);
    set(ref(db, `webrtc/${tableId}/restart`), true);
  }

  const canvasRef = React.useRef({});
  const playerRef = React.useRef({});
  const cueballRef = React.useRef({});
  const [, setState] = React.useState();
  const forceUpdate = React.useCallback(() => setState({}), []);
  const [rosterMapping, setRosterMapping] = useState({});

  const [roster, setRoster] = useState([])



  React.useEffect(() => {
    const updateRosterStatus = (participantId) => {
      console.log("participantId: ", participantId)
      console.log("muted: ", muted);
      participantId = participantId.split("_")[0];
      const db = getFirestore(app);
      const tableRosterRef = doc(db, "rosters", tableId);
      console.log("canvasRef.current.getColorInHexFormat(): ", canvasRef.current.getColorInHexFormat());
      setDoc(tableRosterRef, {
        membersData: {
          [participantId]: {
            color: canvasRef.current.getColorInHexFormat(),
            muted: muted
          }
        }
      }, { merge: true })
    }

    const updateRTCAudioStatus = () => {
      try {
        // if muted is true, mute the microphone
        console.log("playerRef.current: ", playerRef.current);
        if (muted) {
          if (playerRef.current.localStream) {
            console.log("mute the microphone")
            playerRef.current.localStream.mute('both');
          }
        } else {
          if (playerRef.current.localStream) {
            console.log("unmute the microphone")
            playerRef.current.localStream.unmute('both');
          }
        }
      } catch (error) {
        console.error("Error muting the microphone: ", error);
      }
    }
    
    updateRTCAudioStatus()
    updateRosterStatus(userId);
  }, [muted]);


  const hideHints = () => {
    document.getElementById("commentator-hints").classList.add("hidden");
  }
  const showHints = () => {
    document.getElementById("commentator-hints").classList.remove("hidden");
  }
  const toggleHints = () => {
    if (document.getElementById("commentator-hints").classList.contains("hidden")) {
      showHints();
    } else {
      hideHints();
    }
  }

  // hide the hint after 30 secs
  setTimeout(hideHints, 30000);

  const handleClear = () => {
    // Call the handleClear method of the Canvas component
    if (canvasRef.current){
      canvasRef.current.handleClear();
    }

    if (cueballRef.current){
      cueballRef.current.handleClear();
    }
  };

  const handleCueballToggle = () => {
    if (cueballRef.current) {
      cueballRef.current.handleToggle();
    }
  }

  const handleMute = () => {
    setMuted(!muted);
  }

  // iOS blocked the audio connection, so we need to enable it manually
  const enableIosAudio = () => {
    // turn on full screen mode
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.webkitRequestFullscreen) { /* Safari */
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) { /* IE11 */
      document.documentElement.msRequestFullscreen();
    }
    document.documentElement.style.zoom = '0.65';

  }

  const onRosterChange = (event) => {
    console.log("onroasterchange: ", event);
    console.log("current roster: ", playerRef.current.roster);

    setRoster(playerRef.current.roster);
  }

  const getParticipant = (participantId) => {
    // Get the participant document from Firestore
    participantId = participantId.split("_")[0];

    if (rosterMapping.length === 0) { 
      console.log("rosterMapping is null");
      return {};
    }

    if (rosterMapping[participantId] !== undefined) {
      return rosterMapping[participantId];
    }

    return { name: participantId, title: "Commentator" };
  }


  useEffect(() => {
    console.log("useEffect roster")
    async function getCommentators() {
      if (Object.keys(rosterMapping).length === 0) {
        const db = getFirestore(app);
        const querySnapshot = await getDocs(collection(db, "commentators"));
        querySnapshot.forEach((doc) => {
          rosterMapping[doc.id] = doc.data();
          forceUpdate();
        });
        console.log("rosterMapping updated.");
      }
    }
    getCommentators();
  }, [roster]);

  return (
    <div className="streamcontainer">
        <div id="commentator-toolbar" className="absolute mt-4 ml-4 z-[3000]">
          <button className="p-2 font-bold hover:bg-violet-500 group-hover:text-white group" onClick={handleMute}>
            {muted ?
              <span className="text-red-500 group-hover:text-white">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 inline-block mr-1" viewBox="0 0 24 24" fill="none">
                  <path xmlns="http://www.w3.org/2000/svg" d="M15 9.4V5C15 3.34315 13.6569 2 12 2C10.8224 2 9.80325 2.67852 9.3122 3.66593M12 19V22M8 22H16M3 3L21 21M5.00043 10C5.00043 10 3.50062 19 12.0401 19C14.51 19 16.1333 18.2471 17.1933 17.1768M19.0317 13C19.2365 11.3477 19 10 19 10M12 15C10.3431 15 9 13.6569 9 12V9L14.1226 14.12C13.5796 14.6637 12.8291 15 12 15Z" stroke="#EF4444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span> :
              <span className="text-lime-500 group-hover:text-white">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 inline-block mr-1" viewBox="0 0 24 24" fill="none">
                  <path xmlns="http://www.w3.org/2000/svg" d="M19 10V12C19 15.866 15.866 19 12 19M5 10V12C5 15.866 8.13401 19 12 19M12 19V22M8 22H16M12 15C10.3431 15 9 13.6569 9 12V5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5V12C15 13.6569 13.6569 15 12 15Z" stroke="#84CC16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </span>
            }
          </button>
          {getParticipant(userId).admin && <button className="p-2 font-bold hover:bg-violet-500 hover:text-white" onClick={restartWebrtcPublish}>RELOAD</button>}
          <button className="p-2 font-bold hover:bg-violet-500 hover:text-white" onClick={handleClear}>CLEAR</button>
          <button className="p-2 font-bold hover:bg-violet-500 hover:text-white" onClick={handleCueballToggle}>CUEBALL</button>
          <button className="p-2 font-bold hover:bg-violet-500 hover:text-white" onClick={toggleHints}>HINTS</button>
          <button className="p-2 font-bold hover:bg-violet-500 hover:text-white hidden" onClick={enableIosAudio}>FULLSCREEN</button>
        </div>
        <div id="commentator-hints" className='absolute m-4 right-12 text-white z-[3000]'>
          <p className="text-2xl">Welcome, <b>{getParticipant(userId).name}</b></p>
          <p><b>Draw tool:</b> Left mouse key to draw, hold the right key to draw a straight line.</p>
          <p><b>Cueball:</b> Left-click to mark, Right-Hold to drag the cueball.</p>
          <p><b>Ctrl+</b> and <b>Ctrl-</b> to zoom in/out the video if it's too large/small.</p>
        </div>
        <div id="commentator-roster" className='absolute m-4 right-12 text-white z-[3000] hidden'>
          <div className="flex items-center">
            <img className="w-10 h-10 rounded-full mr-4 border-[3px] border-green-500" src={getParticipant(userId).profile_pic} alt={getParticipant(userId).name} />
            <div>
              <p className="text-white font-bold">{getParticipant(userId).name}</p>
              <p className="text-gray-400 font-bold">You ({getParticipant(userId).title})</p>
            </div>
          </div>
          {roster.map((participant) => (
            <div key={participant} className="flex items-center mt-2">
              <img className="w-10 h-10 rounded-full mr-4 border-2 border-white" src={getParticipant(participant).profile_pic} alt={getParticipant(participant).name} />
              <div>
                <p className="text-white font-bold">{getParticipant(participant).name}</p>
                <p className="text-gray-400">{getParticipant(participant).title}</p>
              </div>
            </div>
          ))}
        </div>
        <Player cinemaMode={false} thisRef={playerRef} userId={userId} onRosterChange={onRosterChange}/>
        <Cueball x={0} y={0} size={200} className="hidden z-[4000]" tableId={location.pathname.substring(1)} thisRef={cueballRef} />
        <Canvas tableId={location.pathname.substring(1)} thisRef={canvasRef} />
    </div>
  )
}

function Player({ cinemaMode, thisRef, userId, onRosterChange }) {

  useScript('https://cdn.webrtc-experiment.com/hark.js');
  const location = useLocation()
  const tableId = location.pathname.substring(1);
  const [userVerified, setUserVerified] = useState(false);
  const [speaking, setSpeaking] = useState(false);

  const verifyCommentator = async () => {
    const firestoreDb = getFirestore(app);
    const commentatorDocRef = doc(firestoreDb, "commentators", userId);
    const commentatorSnap = await getDoc(commentatorDocRef);

    // if commentator doesn't exist, exit
    if (!commentatorSnap.exists()) {
      alert("Commentator does not exist.");
      // redirect browser to blank page
      window.location.href = "about:blank";
      return;
    }

    // if commentator has a property 'code', then use an alert to verify for the code
    if (commentatorSnap.data().code) {
      const code = prompt("EvoSports Commentator PIN Code: ");
      if (code == commentatorSnap.data().code) {

      } else {
        alert("PIN Code is incorrect. Please try again or contact EvoSports Support.");
        // reload the page
        window.location.reload();
      }
    }
    setUserVerified(true);
  };

  const startVideoConnection = () => {
    videoConnection = new RTCMultiConnection() // eslint-disable-line
    setDefaultVideoConnectionSettings(videoConnection, tableId, userId);

    videoConnection.enableLogs = false;
    // this is to receive video so modify from the default setting
    videoConnection.sdpConstraints.mandatory = {
      OfferToReceiveAudio: false,
      OfferToReceiveVideo: true
    }

    // auto-join-room
    const reCheckRoomPresence = () => {
      videoConnection.checkPresence(`${tableId}video`, function (isRoomExist) {
        if (isRoomExist) {
          videoConnection.join(`${tableId}video`);
          video_joined = true;
          return;
        }

        console.log("room does not exist");
        setTimeout(reCheckRoomPresence, 5000);
      });
    };
    reCheckRoomPresence();

    videoConnection.onclose = (event) => {
      console.log("VIDEO: Entire session closed: ", event);
      console.log("VIDEO: Retrying connection...");
      video_joined = false;
      setTimeout(() => {
        if (!video_joined) {
          videoConnection.join(`${tableId}video`, (event) => {
            video_joined = true;
          });
        }
      }, 2000);
    }
  }


  // start the audio connection for commentators (audio conference)
  const startAudioConnection = async () => {

    audioConnection = new RTCMultiConnection() // eslint-disable-line
    audioConnection.userid = userId + "_" + audioConnection.token() || audioConnection.token();

    console.log("AUDIO: current userid:", audioConnection.userid)
    audioConnection.socketURL = defaultSocketURL;
    audioConnection.channel = audioConnection.sessionid = `${tableId}audio`;

    audioConnection.session = {
      audio: true,
      video: false,
      data: true
    };

    audioConnection.mediaConstraints = {
      audio: true,
      video: false
    }

    audioConnection.sdpConstraints.mandatory = {
      OfferToReceiveAudio: true,
      OfferToReceiveVideo: false
    }

    audioConnection.iceServers = await getIceServers();
    audioConnection.videosContainer = document.querySelector("#audio-container");
    audioConnection.autoCreateMediaElement = true;
    audioConnection.enableLogs = false;

    audioConnection.onUserStatusChanged = function (event, dontWriteLogs) {
      if (!!audioConnection.enableLogs && !dontWriteLogs) {
        console.info("AUDIO: ", event.userid, event.status);
      }

      console.log("participants: ", audioConnection.getAllParticipants());
      thisRef.current.roster = audioConnection.getAllParticipants();
      onRosterChange(event)
    };

    const joinAudioConnection = () => {
      audioConnection.userid = userId + "_" + audioConnection.token() || audioConnection.token();
      audioConnection.join(`${tableId}audio`, () => {
        thisRef.current.roster = audioConnection.getAllParticipants();
        thisRef.current.localStream = audioConnection.attachStreams[0];
        thisRef.current.audioConnection = audioConnection;
        onRosterChange();
        console.log("AUDIO: audioConnection: ", audioConnection);
        audio_joined = true;

        let firstUserAudioTrack = audioConnection.attachStreams[0].getAudioTracks()[0];
        let otherUserAudioTracks = audioConnection.attachStreams[0].getAudioTracks().slice(1);
        console.log("AUDIO: firstUserAudioTrack: ", firstUserAudioTrack);
        console.log("AUDIO: otherUserAudioTracks: ", otherUserAudioTracks);
      });
    }

    function initHark(args) {
      if (!window.hark) {
        throw 'Please link hark.js';
        return;
      }

      var connection = args.connection;
      var streamedObject = args.streamedObject;
      var stream = args.stream;

      var options = {
        interval: 1000,
      };
      var speechEvents = window.hark(stream, options);

      speechEvents.on('speaking', function () {
        console.log("speechEvent: speaking");
        if (connection.onspeaking) {
          connection.onspeaking(streamedObject);
        }
      });

      speechEvents.on('stopped_speaking', function () {
        console.log("speechEvent: stopped speaking");
        if (connection.onsilence) {
          connection.onsilence(streamedObject);
        }
      });

      speechEvents.on('volume_change', function (volume, threshold) {
        // volumn around -150 (not speaking) ~ -40 (speaking), threshold around -50
        if (connection.onvolumechange) {
          connection.onvolumechange(streamedObject, { volume: volume, threshold: threshold });
        }
      });
    }

    const db = getDatabase(app);
    const speakingRef = ref(db, `speaking/${tableId}/${userId}/speaking`);
    const volumeRef = ref(db, `speaking/${tableId}/${userId}/volume`);
    var harkTimer = 0;

    audioConnection.onspeaking = function (e) {
      set(speakingRef, true);
    }

    audioConnection.onsilence = function (e) {
      set(speakingRef, false);
    }

    audioConnection.onvolumechange = function (e, effect) {
      // every 200 times fire once
      if (harkTimer === 0) {
        try {
          // only if the volume is greater than -150, then set the volume
          if (effect.volume > -150) {
            set(volumeRef, effect.volume);
          }
        }
        catch (error) {
          console.error("Error setting volume: ", error);
        }
        
      }
      harkTimer = (harkTimer + 1) % 1000;
    }

    audioConnection.onstream = function (event) {
      initHark({
        stream: event.stream,
        streamedObject: event.mediaElement,
        connection: audioConnection,
      });
    }

    audioConnection.onclose = (event) => {
      audio_joined = false;
      console.log("AUDIO: Entire session closed: ", event);
      console.log("AUDIO: Retrying connection...");

      setTimeout(() => {
        if (!audio_joined) {
          startAudioConnection();
        }
      }, 3000);
    }

    joinAudioConnection();
  };

  const clickStartButton = () => {
    let startButton = document.getElementById("startButton");
    let startNoAudioButton = document.getElementById("startNoAudioButton");
    startVideoConnection();
    if (audioMode) {
      startAudioConnection();
    }
    startButton.style.display = "none";
    startNoAudioButton.style.display = "none";
  }

  const clickStartNoAudioButton = () => {
    let startButton = document.getElementById("startButton");
    let startNoAudioButton = document.getElementById("startNoAudioButton");
    startVideoConnection();
    startButton.style.display = "none";
    startNoAudioButton.style.display = "none";
  }


  React.useEffect(() => {
    const start = async () => {
      navigator.mediaDevices.getUserMedia({ audio: true }).then(s => {
        // receive the video broadcasting from the publish page
      });

      return function cleanup() {
        const closeConnections = (connection) => {
          if (connection) {
            connection.attachStreams.forEach(function (localStream) {
              localStream.stop();
            });
            connection.closeSocket(connection.sessionid);
          }
        }
        closeConnections(videoConnection);
        closeConnections(audioConnection);
      }
    };

    start();
  }, [location.pathname])

  React.useEffect(() => {
    verifyCommentator();
  }, []);

  return (
    <>
      <div id="video-container" className='streamsize z-[1000]'></div>
      <div id="audio-container" className='text-white z-[1000] hidden'>
        <p>Audio</p>
      </div>
      { userVerified && 
      <><button
          id="startButton"
          onClick={clickStartButton}
          className="p-2 font-bold hover:bg-red-400 bg-violet-500 hover:text-white"
          style={{
            position: 'absolute',
            top: '25%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '20px', // Increase the font size
            padding: '10px 20px', // Add some padding
            border: 'none', // Remove the border
            cursor: 'pointer', // Change the cursor on hover
            fontWeight: 700,
            fontSize: '2rem',
            zIndex: '3000',
            height: 80,
            width: 300
          }}
        >COMMENTATE</button>
        <button
          id="startNoAudioButton"
          onClick={clickStartNoAudioButton}
          className="p-2 font-bold bg-blue-700 hover:bg-blue-500 hover:text-white"
          style={{
            position: 'absolute',
            top: '33%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '20px', // Increase the font size
            padding: '10px 20px', // Add some padding
            border: 'none', // Remove the border
            cursor: 'pointer', // Change the cursor on hover
            fontWeight: 300,
            fontSize: '1.2rem',
            zIndex: '3000',
            height: 50,
            width: 300
          }}
        >NO AUDIO MODE</button></>
      }
    </>
  )
}

export default PlayerPage
